// @import "./variable";

.text-center {
  text-align: center;
}

.mat-select-arrow {
  color: $button-primary-color;
}

// .global-side-nav{
//   .mdc-list-item__content .mdc-list-item__primary-text {
//     order: 2;
//     margin-left: 10px;
//     display: flex;
//     align-items: center;
//     font-family: "Arial";
//   }
//   .mdc-list-item .mat-mdc-list-item-unscoped-content {
//     max-width: 30px;
//     min-width: 20px;
//     width: 100%;
//     margin-right: 10px;
//   }
//   .mdc-list-item .mat-mdc-list-item-unscoped-content .check-header-icon {
//     display: none;
//   }
//   .mdc-list-item .mat-mdc-list-item-unscoped-content .active-check-header-icon {
//     display: block;
//   }
//   .mdc-list-item.mdc-list-item--with-one-line {
//     height: 48px;
//   }
//   .check-header-icon{
//     max-width: 30px;
//   }
//   .mdc-list-item__content {
//     display: flex;
//   }
// }

.dashboard-efficiency-section{
  .efficiency-section-desc {
    margin-left: 30px;
    .mat-mdc-card-header-text {
        margin: 0 5px;
    }
  }
}



