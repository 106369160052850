@import "./partials/variable";
@import "./partials/style-guide";
@import "./partials/fonts";

// body {
//   background-color: #f4f3f9;
// }

.p-20 {
  padding: 20px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mt-6 {
  margin-top: 60px;
}

.mt-7 {
  margin-top: 70px;
}
.mt-0 {
  margin-top: 0;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 20px;
}
.mb-60 {
  margin-bottom: 20px;
}
.mb-0 {
  margin-bottom: 0px;
}

.space-right {
  margin-right: 10px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.display-block {
  display: block;
}

.posR {
  position: relative;
}

.cursor-point {
  cursor: pointer;
}

.no-cursor {
  cursor: none;
}

input {
  font-family: "Helventica";
}

input[readonly="true"] {
  color: rgba(0, 0, 0, 0.38) !important;
}

.readOnly-feild {
  input[readonly="true"] {
    color: red;
  }
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  background-color: $button-primary-color;
  font-family: "Larssiet-medium";
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mdc-button.mat-warn {
  color: $button-primary-color;
  font-family: "Larssiet-medium";
}

.close-or-back-icon {
  position: absolute;
  top: 30px;
  left: 30px;
}

.user-img-holder {
  width: 55px;
  height: 55px;
  position: relative;
  border-radius: 50%;
  background-color: #d9d6e3;

  .user {
    line-height: 55px;
    text-align: center;
    display: none;

    &.user-selected {
      display: block;

      +.img-alt,
      +.img-replacer {
        display: none;
      }
    }
  }

  &.hover-effect:hover {

    .img-alt,
    .img-replacer {
      display: none;
    }

    .user {
      display: block;
    }
  }

  .img-alt {
    font-size: 20px;
    color: $button-accent-color-light;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .img-replacer {
    width: 100%;
    height: 100%;
    // object-fit: none;
    border-radius: 50%;
  }
}

tr.table-row {
  height: 65px !important;
}

tr.users {
  height: 90px !important;
}

button.heading-button {
  min-width: 120px;
}

.user-related-information-card {
  padding: 10px 40px 10px 40px;

  mat-card-header {
    background: $brand-accent-color;
    padding: 15px 0;
    color: $white-color;
    border-radius: 10px 10px 0px 0px;
  }

  ::ng-deep .mat-mdc-card-header-text {
    width: 100%;
    text-align: center;
  }

  mat-card-content {
    padding: 10px;
  }

  mat-card-title {
    margin: 0 !important;
    font-family: "Larssiet-medium";
  }

  mat-card {
    padding: 0;
    border-radius: 10px;
    width: 300px;
  }

  .info-column {
    padding: 15px 20px 10px 20px;

    h5 {
      color: $brand-accent-color;
      font-size: 16px;
      font-family: "Helventica-Bold";
    }

    p {
      font-family: "Helventica";
    }
  }
}

.search-box {
  input {
    width: 100%;
    height: 41px;
    padding: 0;
    border-radius: 5px;
    font-family: "Helventica";
    font-size: 18px;
    outline: none;
    background-color: $white-color;
    border: 1px solid $white-color;
    text-indent: 15px;
    box-shadow: 0px 0px 5.34px 0.66px rgb(0 0 0 / 27%);

    &::placeholder {
      color: $grey-input-lighter;
    }
  }

  .search-icon {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;
    height: 20px;
    width: 20px;
  }
}

.dialog-top-bar {
  background-image: linear-gradient(-86deg, #2d2d2d 0%, #5f5f61 100%);
}

.title {
  color: $white-color;
  font-size: 22px;
  font-family: "Larssiet-medium";

  &.title-small {
    font-size: 18px;
  }
}

.cdk-overlay-pane.mat-mdc-dialog-panel.addsystem {
  max-width: 100%;
}
.custom-dialog {
  &.border-radius {
    .mat-mdc-dialog-container {
      border-radius: 10px;
      filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.16));
      overflow-x: hidden;

      .dialog-top-bar {
        border-radius: 10px 10px 0 0;
      }

      .content-space {
        padding: 30px 15px;
        margin: 0;

        .heading {
          font-size: 18px;
          line-height: 30px;
          color: $grey-black;
          font-family: "Larssiet-medium";
        }
      }
    }
  }

  .mat-mdc-dialog-container {
    padding: 0;
    margin: 0;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    background: white;
    color: rgba(0, 0, 0, 0.87);

    .logo {
      margin: 0 10px 10px 0;
    }

    .content-section {
      margin: 40px;
    }

    .btn-lg {
      width: 187px;
      height: 52px;
      border-radius: 5px;
      filter: drop-shadow(0 0 2.5px rgba(0, 0, 0, 0.2));
    }
  }
}

.custom-chip {
  width: 234px;
  height: 50px;
  border-radius: 20px;
  background-color: $white-color;
  border: 1px solid $button-primary-color;
  display: inline-block;

  .text-content {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    line-height: 30px;
    color: $button-primary-color;
    font-family: "Larssiet";
  }
}

.no-style {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

.list-container {
  padding: 30px;
}

.custom-table {
  &.mat-mdc-table {
    border-radius: 10px;
    overflow: hidden !important;

    thead {
      background-color: $light-blue;
      font-family: "Larssiet-medium";

      .mat-header-cell {
        font-size: 16px;
      }
    }

    tr.mat-row {
      font-family: "Larssiet";

      td {
        font-size: 16px;
        color: $grey-dark;
      }
    }

    tr.mat-mdc-row:hover {
      background-color: $light-pink;
    }

    thead,
    tr.mat-mdc-row:hover {
      filter: drop-shadow(0 0 2.5px rgba(0, 0, 0, 0.21));
    }
  }
}

.color-orange-tag {
  color: $button-primary-color;
}

.secondary-data {
  margin-top: 5px;
}

.action-button-group {
  margin-top: 40px;

  button {
    min-width: 120px;
  }
}

.assign-name {
  font-size: 16px;
  color: $brand-accent-color;
  font-family: "Larssiet-medium";
}

.description {
  font-size: 16px;
  color: $grey-dark;
  font-family: "Larssiet";
}

// sidenav
.sidenav-bar {
  height: 100%;

  .profile-padding {
    padding: 20px;
    border-bottom: 2px solid #e2e2e2;
  }

  .global-mat-sidenav {
    width: 340px;
  }

  mat-sidenav {
    width: 394px;

    .title-header {
      margin: 20px;
    }

    .mat-drawer-inner-container {
      position: relative;
    }

    .sidebar-list-view {
      height: calc(100% - 206px);
      overflow-y: scroll;

      .list-item {
        padding: 0 20px;

        &.top-padding {
          padding: 5px 20px;
        }

        &.top-padding-no-img {
          padding: 15px 20px;
        }

        &.active {
          .tick-icon svg path {
            fill: $green-color;
          }
        }

        &.active,
        &:hover {
          background-color: $light-pink;
        }

        border-bottom: 2px solid $border-light-grey;

        .serial-no {
          font-size: 14px;
          // line-height: 30px;
          color: #8e8e8e;
        }
      }
    }

    .bottom-fixed {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 15px;
    }
  }
}

.note,
.secondary-section {
  color: $grey-lighter;
  font-family: "Helventica";
}

.note {
  font-size: 18px;
}

.secondary-section {
  font-size: 15px;
}

.tabs-wrapper {
  padding: 20px;

  .tabs-group {
    .tab-container {
      padding: 10px;
    }
  }
}

.mat-mdc-tab-group.mat-warn {
  .mat-ink-bar {
    background-color: $button-primary-color;
  }

  .mat-tab-label {
    color: black;
    font-family: "Larssiet";
    font-size: 16px;

    &.mdc-tab--active {
      color: $button-primary-color;
    }
  }
}

.mat-drawer-container {
  background-color: $body-color;
}

/*ellipsis classes*/
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.ellipsis-335 {
  @extend .ellipsis;
  max-width: 335px;
}

mat-icon.warning svg path {
  fill: $button-primary-color;
}

.date-label,
.input,
.mat-select,
.mat-radio-label-content {
  font-family: "Larssiet";
}

.blur-effect {
  opacity: 0.3;
}

.mat-mdc-tab-group.mat-warn .mat-tab-label.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.status {

  &.active,
  &.online {
    color: $green-color;
  }

  &.suspended,
  &.suspend {
    color: $button-primary-color;
  }

  &.warning,
  &.offline {
    color: #d63135;
  }

  &.critical {
    color: #a12a49;
  }

  &.alert {
    color: #e7913a;
  }

  &.normal {
    color: #56a64f;
  }

  &.unknown {
    color: #a0a0a0;
  }

}

.text-width-ellipsis {
  width: 100%;
  max-width: 400px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.empty-screen {
  h4 {
    color: $brand-primary-color;
    margin: 15px 0;
  }

  p {
    margin-bottom: 15px;
  }

  .empty-screen-img {
    width: 100%;
    max-width: 300px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.mat-toolbar.dialog-top-bar {
  background-image: linear-gradient(-86deg, #2d2d2d 0%, #5f5f61 100%);
}

.mdc-button.mat-warn {
  background-color: #FFFFFF;
  border-color: #EF4135!important;
  border-radius: 50px;
  min-width: 115px;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab{
  flex-grow: 0;
}
.mat-raised-button.mat-primary,
.mat-raised-button {
  background-color: #EF4135;
  border-color: #EF4135;
  color: #ffffff;
  border-radius: 50px;
}

.custom-dialog {
  &.border-radius {
    .mat-mdc-dialog-container {
      
      border-radius: 10px;
      filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.16));

      // .dialog-top-bar {
      //   border-radius: 10px 10px 0 0;
      // }
      dialog-top-bar {
        border-radius: 0px;
      }

      .content-space {
        padding: 30px 15px;

        .heading {
          font-size: 18px;
          line-height: 30px;
          color: $grey-black;
          font-family: "Larssiet-medium";
        }
      }
    }
  }
}

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.hide_single_system {
  .mat-mdc-tab-header .mat-mdc-tab {
    display: none !important;
  }

  .mat-tab-label {
    display: none !important;
    /* Hide tab labels */
  }
}
mat-password-strength-info .mat-mdc-card {
  background: transparent;
  box-shadow: none;
  color: #666666;




  .mat-icon.mat-primary {
      --mat-icon-color: #666666;


  }
  .mat-icon.mat-warn {
      --mat-icon-color:  #666666;
  }
}
.otp-input{
  width: 35px!important;
    height: 35px!important;
    text-transform: uppercase;
}
.mat-mdc-outlined-button .mdc-button__label{
 // margin-bottom: 1px!important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: purple !important;
}

.mat-card-title.mat-mdc-card-title.gallons-count{
  margin: auto!important;
}