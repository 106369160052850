// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

//Custom theme for the application
//primary color we are choosing here -- #998ac0  ||  Secondary color we are choosing here --#5a5a5a

/* For use in src/lib/core/theming/_palette.scss */
$brand-primarycolor: (
  50: #f3f1f7,
  100: #e0dcec,
  200: #ccc5e0,
  300: #b8add3,
  400: #a89cc9,
  500: #998ac0,
  600: #9182ba,
  700: #8677b2,
  800: #7c6daa,
  900: #6b5a9c,
  A100: #ffffff,
  A200: #ded3ff,
  A400: #b7a0ff,
  A700: #a486ff,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$brand-accentcolor: (
  50: #f3f1f7,
  100: #e0dcec,
  200: #ccc5e0,
  300: #b8add3,
  400: #a89cc9,
  500: #998ac0,
  600: #9182ba,
  700: #8677b2,
  800: #7c6daa,
  900: #6b5a9c,
  A100: #ffffff,
  A200: #ded3ff,
  A400: #b7a0ff,
  A700: #a486ff,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
//@include mat.all-component-themes($brand-theme);

//@include mat.typography-angular();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$main-app-primary: mat.m2-define-palette($brand-primarycolor);
$main-app-accent: mat.m2-define-palette($brand-accentcolor);

// The warn palette is optional (defaults to red).
$main-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$main-app-theme: mat.m2-define-light-theme((color: (primary: $main-app-primary,
        accent: $main-app-accent,
        warn: $main-app-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($main-app-theme);
@import "./stylesheet/partials/variable";
/* You can add global styles to this file, and also import other style files */

html,
body,
body.mat-typography {
  height: 100%;
  margin: 0;
  font-family: "Helvetica";
}

a {
  color: #EF4135;
  text-decoration: none;
}

.mat-mdc-card {
  background-color: #f5f4ffff !important;
  max-width: 390px;
  width: 100%;
  // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);;
  // box-shadow: 0px 0px 5.48px 1.52px rgba(99, 94, 155, 0.31);
  box-shadow: 0px 0px 18.48px 5.52px rgba(99, 94, 155, 0.31);
  border-radius: 10px;
  
}
.login{
  .mat-mdc-card {
    max-width: 100%;
    width: 100%;
    
    
  }
}
.global-side-nav{
  .mdc-list-item {
    cursor: pointer !important;
    line-height: 0px !important;
  }
  .mat-mdc-list-base .mdc-list-item__content {
    padding: 0px 16px;
  }
  .mdc-list-item__content .mdc-list-item__primary-text{
    max-width: 200px;
  }

  .mac-system-id.custom-height .mdc-list-item.mdc-list-item--with-one-line {
    height: 65px;
  }
} 
.mdc-list-item {
  line-height: 48px !important;

  .mat-mdc-list-item-unscoped-content {
    max-width: 30px;
    min-width: 20px;
    width: 100%;
    margin-right: 0px;
    .systems-list{
               
      margin-right: 0px!important;
 
}

    // .check-header-icon {
    //   display: none;
    // }
    .check-header-icon {
      display: block;
    svg path {
          fill:  #808080!important;font-weight: bold;
          font-size: 30px;
        }

  }

    .active-check-header-icon {
      display: block;
    }
    .active-check-header-icon-systems {
      display: block;
      svg path {
          fill:  #5eb957!important;
          font-weight: bold;
          font-size: 30px;
        }
  }

  .active-check-header-icon-systems-disable{
display: none!important;
  }
  }

  mat-icon svg {
    vertical-align: top;
  }
}
.related-info-card{
  .section-header .border-section {
    width: 55% !important;
    left: 45%;
  }
}
.mdc-list-item{
  font-family: "Larssiet";
}

// .mdc-list-item:focus,.mdc-list-item:hover,.mdc-list-item.active {
//   background-color: $body-color!important;
// }

.mdc-list-item:focus::before,.mdc-list-item:hover::before,.mdc-list-item.active::before {
  background-color: $body-color!important;
}
.mdc-list-item:focus .mdc-list-item__primary-text, .mdc-list-item:hover .mdc-list-item__primary-text,.mdc-list-item.active .mdc-list-item__primary-text {
  color: $brand-primary-color!important;
  mat-icon svg path {
    fill: $brand-primary-color!important;
  }
}

.cdk-global-overlay-wrapper {
  justify-content: center!important;
}
.cursor-pointer{
  cursor: pointer!important;
}
.globalsetting{
  .section-header .border-section {
    width: 78%!important;
    left: 22%;
  }
}
mat-icon.setup-icon{
  svg {
    path {
      color: rgb(156, 141, 195) !important;
    }
  }
} 
.status {

  &.active,
  &.cleared {
    color: green;
  }

  &.suspended,
  &.suspend,


  &.warning {
    color: #d63135;
  }

  &.critical {
    color: #a12a49;
  }

  &.alert {
    color: #e7913a;
  }

  &.normal {
    color: #56a64f;
  }

  &.unknown,
  &.unclear {
    color: #a0a0a0;
  }

  &.forced_cleared {
    color: #3c78d8;
  }

  &.activity {
    color: #067C00;
  }

  &.health {
    color: #66DB25;
  }

  &.operational {
    color: #2D93FA;
  }

  &.statistic {
    color: #556F7B;
  }

  &.system_mode {
    color: #FF9800;
  }

  &.error {
    color: #E91E63;
  }

  &.software_update {
    color: #a64d79;
  }

  &.config_update {
    color: #45818e;
  }

  &.system_info {
    color: #6d9eeb;
  }

  &.remote_command {
    color: #b45f06;
  }

  &.running {
    color: #b45f06;
  }

  &.not-running {
    color: #434343 !important;
  }

  &.completed {
    color: #6aa84f;
  }

  &.immediate {
    color: #a64d79;
  }

  &.enabled {
    color: #6aa84f;
  }

  &.normal {
    color: #6aa84f;
  }

  &.high {
    color: #FF9800;
  }

  &.disabled {
    color: #434343;
  }

  &.in-future {
    color: #3c78d8;
  }

  &.inactive {
    color: #434343;
  }

  &.in-progress {
    color: #fab963;
  }

  &.waiting {
    color: #434343 !important;
  }

  /* Define CSS classes for different system statuses */
  &.connected {
    color: #067C00;
  }

  &.recently_connected {
    color: #66DB25;
  }

  &.recently_disconnected {
    color: #2D93FA;
  }

  &.disconnected {
    color: #556F7B;
  }

  &.offline {
    color: #FF9800;
  }

  &.never_connected {
    color: #E91E63;
  }

  &.never-used {
    color: #595959;
  }

  &.in-used {
    color: #6aa84f;
  }

  &.rolled-out {
    color: #E91E63;
  }


}

.mdc-list-item__content {
  display: flex;
  pointer-events: none!important;
  .mdc-list-item__primary-text {
    order: 2;
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-family: 'Arial';
    max-width: 130px;
    
  }
}


.switch:enabled:hover:not(:focus):not(:active) .switch__track::after {
  background: #ef4135;
}

.switch.switch--selected:enabled:hover:not(:focus):not(:active) .switch__handle::after {
  background: #ef4135;
}

.switch.switch--unselected:enabled .switch__handle::after {
  background: #ef4135 !important;
}

.switch.switch--unselected:enabled:hover:not(:focus):not(:active) .switch__handle::after {
  background: #ef4135;
}

.switch:enabled:hover:not(:focus):not(:active) .switch__track::before {
  background: #ffffff;
  border: 1px solid #ef4135;
}

.switch:enabled .switch__track::before {
  background: #ffffff;
  border: 1px solid #ef4135;
}

.mat-mdc-list-item-content .active-check-header-icon {
  display: block !important;
}

.mat-mdc-list-item-content .check-header-icon {
  display: none;
}

.settings,
.system-info-section {
  .tab--active {
    background-color: transparent;
  }
}

.custom-request-submit-dialog {
  .mat-mdc-dialog-container {
    .dialog__surface {
      background-color: transparent;
      box-shadow: none !important;
    }
  }
}

.set-up-section .right-arrow mat-icon {
  color: #ef4135 !important;
}
.mat-mdc-list-item {
  .mat-mdc-list-item-content {
    .matListItemTitle {
      max-width: 30px;
      margin-left: 40px;
    }
  }
}

.font-bold {
  font-weight: 600 !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid transparent;
}

.water-saved-report-section {
  display: flex;
  width: 100%;
}

.water-saved-container {
  // display: flex !important;
  // flex-direction: row !important;
  max-width: 100%;
  margin-top: 30px;
}

.mdc-tab--active {
  color: #EF4135 !important;
}
.mat-mdc-icon-button .mat-mdc-button-,.mat-mdc-icon-button .mat-mdc-button-touch-target,.mat-mdc-icon-button .mat-mdc-focus-indicator,span.mat-mdc-button-persistent-ripple.mdc-icon-button__ripple {
  &:focus{
    background-color: transparent;
    opacity: 0;
  }
  &:focus-visible{
    background-color: transparent;
    opacity: 0;
  }
  &:focus-within{
    background-color: transparent;
    opacity: 0;
  }
  &:hover{
    background-color: transparent;
    opacity: 0;
  }
  
}
.mat-tab {
  .tab-indicator__content--underline {
    border-color: #EF4135 !important;
  }
}

.mat-mdc-tab-group.mat-primary,
.mat-tab-nav-bar.mat-primary {
  .mat-ink-bar {
    background-color: #EF4135 !important;
  }
}

.mat-mdc-tab-label-container {
  display: flex;
  flex-grow: initial !important;
}

.hide_single_system {
  .mat-mdc-tab-header .mat-mdc-tab {
    display: none;
  }

  .mat-ripple .mat-tab-label {
    display: none
  }
}

.style-pass-strength-info {
  .mat-icon {
    -webkit-user-select: none;
    user-select: none;
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    overflow: visible;
  }
}
.invite-members-main{
  .mat-mdc-option .mdc-list-item__primary-text{
    white-space: nowrap!important;   
}
.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap!important;
  overflow: hidden;}
}
@import "./stylesheet/master.scss";